import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';
import { actionTypes as paystubs } from './paystubs';

const FETCH = 'lenderspotlight/salesforce/compensations/FETCH';
const FETCH_FAILED = 'lenderspotlight/salesforce/compensations/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/salesforce/compensations/FETCH_SUCCESS';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  FETCH,
  FETCH_FAILED,
  FETCH_SUCCESS,
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  fetchByMortgageId: PropTypes.func,
});

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
const initialState = {};

/**
 * By Id Compensation Recucer.
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  switch (action.type) {
    case paystubs.FETCH_BY_DATE_SUCCESS:
    case paystubs.FETCH_RECONCILIATION_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.compensations,
      };

    default:
      return state;
  }
}

/**
 * ByMortgageId Compensation Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byMortgageIdReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUCCESS:
      return {
        ...state,
        [action.payload.mortgageId]: action.payload.entities.compensations,
      };

    default:
      return state;
  }
}

/**
 * IsLoading Reducer
 * @param  {Boolean} state
 * @param  {Object} action
 * @return {Boolean}
 */
function isLoadingRecucer(state = false, action) {
  switch (action.type) {
    case FETCH:
      return true;

    case FETCH_FAILED:
    case FETCH_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
  byMortgageId: byMortgageIdReducer,
  isLoading: isLoadingRecucer,
});

/**
 * Fetch Mortgages Success Action
 * @param {String} mortgageId
 * @param {Object} response
 * @return {Object}
 */
function fetchSuccess(mortgageId, response) {
  const normalized = normalize(response, [schema.compensation]);
  const payload = { ...normalized, mortgageId };
  return { type: FETCH_SUCCESS, payload };
}

/**
 * Fetch Mortgages Thunk
 * @return {Function}
 */
export function fetchByMortgageId(mortgageId) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/salesforce/mortgages/${mortgageId}/compensations`)
      .then((response) => dispatch(fetchSuccess(mortgageId, response.data)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}
