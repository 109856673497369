import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

const NavListItem = (props) => {
  const classes = [props.className];

  const exclude = Array.isArray(props.except) ? props.except : [props.except];

  const excluded = exclude.reduce((accum, path) => {
    if (props.location.pathname.includes(path)) return true;
    return accum;
  }, false);

  if (props.exact && props.location.pathname === props.to && !excluded) {
    classes.push('active');
  } else if (!props.exact && props.location.pathname.includes(props.to) && !excluded) {
    classes.push('active');
  }

  return (
    <li className={classes.join(' ')}>
      <Link to={props.to} disabled={props.disabled}>
        {props.children}
      </Link>
    </li>
  );
};

/**
 * Default Props
 * @type {Object}
 */
NavListItem.defaultProps = {
  className: 'list-group-item',
  disabled: false,
  exact: false,
  except: [],
};

/**
 * PropTypes Validation
 * @type {Object}
 */
NavListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  except: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  to: PropTypes.string.isRequired,
};

export default withRouter(NavListItem);
