import { combineReducers } from 'redux';
import compensationsReducer, * as compensations from './compensations';
import expensesReducer, * as expenses from './expenses';
import lendersReducer, * as lenders from './lenders';
import mortgagesReducer, * as mortgages from './mortgages';
import paystubsReducer, * as paystubs from './paystubs';

export default combineReducers({
  compensations: compensationsReducer,
  expenses: expensesReducer,
  lenders: lendersReducer,
  mortgages: mortgagesReducer,
  paystubs: paystubsReducer,
});

export { compensations, expenses, lenders, mortgages, paystubs };
