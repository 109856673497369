/**
 * Get all lenders from state.
 * @param  {Object} state
 * @return {Object}
 */
export const getAll = (state) =>
  Object.keys(state.salesforce.lenders.byId).map((lenderId) => state.salesforce.lenders.byId[lenderId]);

/**
 * Check if the lenders are loading
 * @param  {Objext} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.salesforce.lenders.isLoading;
