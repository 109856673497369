import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormFieldPropTypes from './FormFieldPropTypes';

const FormInput = ({ input, meta, className, ...props }) => (
  <input
    {...input}
    {...props}
    className={classnames(className, {
      'is-invalid': meta.touched && meta.error,
    })}
  />
);

FormInput.defaultProps = {
  className: 'form-control',
  type: 'text',
};

FormInput.propTypes = {
  ...FormFieldPropTypes,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default FormInput;
