import React, { forwardRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'components/common';
import Button from './Button';
import useHideIntercom from './useHideIntercom';

const Panel = forwardRef(({ children, isOpen, testId }, ref) => {
  useHideIntercom(isOpen);

  return (
    <div
      className={classnames('panel bg-white border-border border-left-sm d-flex flex-column position-fixed w-100 z-1', {
        'panel--open': isOpen,
      })}
      data-testid={testId}
      ref={ref}
    >
      {children}
    </div>
  );
});

// Since `forwardRef` causes the component to appear as anonymous, need to
// set name explicitly to help with debugging
Panel.displayName = 'Panel';

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  testId: PropTypes.string,
};

const PanelHeader = ({ children, equalXPadding = false, onClose }) => (
  <div className="border-bottom border-gray px-3">
    <div className="align-items-center d-flex height-15">
      {equalXPadding && onClose && (
        <>
          {/** Width and margin match close button */}
          <div className="ml-n2_5" style={{ width: 38 }} />
        </>
      )}

      {children}

      {onClose && (
        <div className="ml-auto">
          <div className="mr-n2_5">
            <Button className="alert--close close" onClick={onClose} testId="panel-close-button">
              <Icon name="xmark" />
            </Button>
          </div>
        </div>
      )}
    </div>
  </div>
);

PanelHeader.propTypes = {
  children: PropTypes.node,
  equalXPadding: PropTypes.bool,
  onClose: PropTypes.func,
};

const PanelBody = ({ children }) => <div className="overflow-auto h-100 px-3 pt-4">{children}</div>;

PanelBody.propTypes = {
  children: PropTypes.node.isRequired,
};

const PanelFooterButton = ({ children, disabled, onClick, testId = 'panel-footer-button' }) => (
  <div className="p-3 bg-white">
    <Button disabled={disabled} onClick={onClick} testId={testId} variant="primary-dark" width="block">
      {children}
    </Button>
  </div>
);

PanelFooterButton.propTypes = {
  children: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

Panel.Header = PanelHeader;
Panel.Body = PanelBody;
Panel.FooterButton = PanelFooterButton;
export default Panel;
