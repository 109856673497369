import React from 'react';
import PropTypes from 'prop-types';
import FormFieldPropTypes from './FormFieldPropTypes';

const FormRadio = ({ input, options }) => (
  <div>
    {options.map((option) => (
      <div className="radio" key={option}>
        <label htmlFor={`${input.name}-${option}`}>
          <input id={`${input.name}-${option}`} type="radio" {...input} value={option} className="mr-1" />
          {option}
        </label>
      </div>
    ))}
  </div>
);

FormRadio.defaultProps = {
  type: 'radio',
};

FormRadio.propTypes = {
  ...FormFieldPropTypes,
  type: PropTypes.string,
};

export default FormRadio;
