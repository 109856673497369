import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ children, variant }) => <span className={`badge badge-${variant}`}>{children}</span>;

Badge.defaultProps = {
  children: undefined,
  variant: 'primary',
};

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  variant: PropTypes.string,
};

export default Badge;
