import * as compensations from './compensations';
import * as expenses from './expenses';
import * as lenders from './lenders';
import * as mortgages from './mortgages';
import * as paystubs from './paystubs';

export default {
  compensations,
  expenses,
  lenders,
  mortgages,
  paystubs,
};
