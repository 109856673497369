import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const FormHelpModal = (props) => (
  <Modal show={props.show} onHide={props.onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{props.children}</Modal.Body>
  </Modal>
);

FormHelpModal.defaultProps = {
  onHide: () => {},
  show: false,
};

FormHelpModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default FormHelpModal;
