import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import NavbarBrand from './NavbarBrand';
import NavbarCollapse from './NavbarCollapse';
import NavbarToggler from './NavbarToggler';

const Navbar = ({ children, fixedTop = false, theme = 'light', ...props }) => (
  <nav
    className={classnames('navbar', 'navbar-expand-lg', `navbar-${theme}`, {
      'fixed-top': fixedTop,
    })}
    {...props}
  >
    {children}
  </nav>
);

Navbar.Brand = NavbarBrand;
Navbar.Collapse = NavbarCollapse;
Navbar.Toggler = NavbarToggler;

/**
 * PropTypes Validation
 * @type {Object}
 */
Navbar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  fixedTop: PropTypes.bool,
  theme: PropTypes.string,
};

export default Navbar;
