import React from 'react';
import PropTypes from 'prop-types';

const FormInputError = (props) => {
  const { error, ...passProps } = props;

  if (!error) return null;

  return (
    <span className="help-block invalid-feedback" {...passProps}>
      {error}
    </span>
  );
};

FormInputError.defaultProps = {
  error: null,
};

FormInputError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
};

export default FormInputError;
