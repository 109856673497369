import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ children, name }) => (
  <label className="font-bold" htmlFor={name}>
    {children}
  </label>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

export default Label;
