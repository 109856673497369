import React from 'react';
import PropTypes from 'prop-types';

const TagPill = ({ children, color = 'primary', icon, testId, textColor = 'white' }) => (
  <span
    data-testid={testId}
    style={{ height: 24 }}
    className={`bg-${color} d-flex align-items-center px-2 rounded-pill text-nowrap text-0_75 font-weight-bold text-${textColor}`}
  >
    {icon && <span className="d-flex align-items-center mr-1">{icon}</span>}
    <span>{children}</span>
  </span>
);

TagPill.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  icon: PropTypes.element,
  testId: PropTypes.string,
  textColor: PropTypes.string,
};

export default TagPill;
