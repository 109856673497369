import React from 'react';
import FormSelectWithGroup from './FormSelectWithGroup';

const options = [
  { id: null, name: 'Please select a province' },
  { id: 'British Columbia', name: 'British Columbia' },
  { id: 'Alberta', name: 'Alberta' },
  { id: 'Saskatchewan', name: 'Saskatchewan' },
  { id: 'Manitoba', name: 'Manitoba' },
  { id: 'Ontario', name: 'Ontario' },
  { id: 'Quebec', name: 'Quebec' },
  { id: 'New Brunswick', name: 'New Brunswick' },
  { id: 'Nova Scotia', name: 'Nova Scotia' },
  { id: 'Prince Edward Island', name: 'Prince Edward Island' },
  { id: 'Newfoundland and Labrador', name: 'Newfoundland and Labrador' },
  { id: 'Yukon', name: 'Yukon' },
  { id: 'Northwest Territories', name: 'Northwest Territories' },
  { id: 'Nunavut', name: 'Nunavut' },
];

const ProvinceSelectWithGroup = (props) => {
  const passProps = {
    ...props,
    options,
  };

  return <FormSelectWithGroup {...passProps} />;
};

export default ProvinceSelectWithGroup;
