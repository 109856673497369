import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Icon } from 'components/common';
import { noop } from 'libs/utils';

const VARIANTS = ['danger', 'error', 'info', 'outline', 'primary-light'];

const CloseButton = ({ onClick, testId }) => (
  <button
    aria-label="Close"
    className="alert--close close"
    data-testid={`${testId}-close-btn`}
    onClick={onClick}
    type="button"
  >
    <Icon name="xmark" />
  </button>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

/**
 * @param {Object} props
 * @param {Boolean} props.isDismissable Adds a close button if `true`
 * @param {Boolean} props.isImportant Since the `alert` role is intrusive,
 * only add it if important. For more details, see
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_alert_role
 */
const Alert = ({
  children,
  isDismissable = false,
  isImportant = false,
  onClose = noop,
  testId = null,
  variant = 'info',
}) => (
  <div
    className={classnames('alert', `alert-${variant}`, {
      'alert-dismissible': isDismissable,
    })}
    data-testid={testId}
    role={isImportant ? 'alert' : null}
  >
    {children}
    {isDismissable && <CloseButton onClick={onClose} testId={testId} />}
  </div>
);

Alert.propTypes = {
  children: PropTypes.node.isRequired,
  isDismissable: PropTypes.bool,
  isImportant: PropTypes.bool,
  onClose: PropTypes.func,
  testId: PropTypes.string,
  variant: PropTypes.oneOf(VARIANTS),
};

export default Alert;
