import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/common';
import Loader from './Loader';

const Loading = ({ error, pastDelay, retry }) => {
  if (error) {
    return (
      <div>
        <span className="mr-2">Error!</span>
        <Button onClick={retry} variant="primary-dark">
          Retry
        </Button>
      </div>
    );
  }

  if (pastDelay) {
    return <Loader isLoading />;
  }

  return null;
};

Loading.propTypes = {
  error: PropTypes.bool,
  pastDelay: PropTypes.bool.isRequired,
  retry: PropTypes.func,
};

Loading.defaultProps = {
  error: false,
  retry: () => {},
};

export default Loading;
