import React from 'react';
import PropTypes from 'prop-types';
import FormSelectWithGroup from './FormSelectWithGroup';

const LenderSelectWithGroup = (props) => {
  const options = props.lenders || [];

  const passProps = {
    ...props,
    options,
  };

  return <FormSelectWithGroup {...passProps} />;
};

LenderSelectWithGroup.defaultProps = {
  lenders: [],
};

LenderSelectWithGroup.propTypes = {
  lenders: PropTypes.arrayOf(PropTypes.object),
};

export default LenderSelectWithGroup;
