import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Icon } from 'components/common';

const FormLabel = ({ htmlFor, name, row, showHelp, ...passProps }) => {
  const onClick = (event) => {
    event.preventDefault();
    return showHelp();
  };

  return (
    <div
      className={classnames({
        'col-md-4': row,
        'text-right': row,
        'font-bold': !row,
      })}
    >
      <label htmlFor={htmlFor} {...passProps}>
        {name}
      </label>
      {showHelp && (
        <div>
          <button type="button" className="btn btn-light" onClick={onClick}>
            <small className="form-text text-muted d-inline">Instructions</small>
            <Icon className="ml-1" name="circle-question" />
          </button>
        </div>
      )}
    </div>
  );
};

FormLabel.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  name: PropTypes.string,
  row: PropTypes.bool,
  showHelp: PropTypes.func,
};

export default FormLabel;
