export const hasCompliance = (mortgage) =>
  mortgage.stage === 'Complete' ||
  mortgage.stage === 'Funded' ||
  mortgage.sub_stage === 'Agent Review' ||
  mortgage.sub_stage === 'Verified' ||
  mortgage.sub_stage === 'Manager Review';

export const isComplete = (mortgage) => mortgage.stage === 'Funded' || mortgage.stage === 'Complete';

export default {
  hasCompliance,
  isComplete,
};
