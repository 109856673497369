import React from 'react';
import classnames from 'classnames';
import { analytics, useResponsiveWidth } from 'libs';

const ChatWithUs = () => {
  const { isMinLg } = useResponsiveWidth();
  const message =
    "Hi, I'm interested in upgrading to Platinum. Could you please provide me with more details about pricing, billing, and teams?";
  const onClick = () => {
    analytics.upgrade.chatWithUsButtonClick();
    window.Intercom('showNewMessage', message);
  };

  return (
    <div className="mr-2">
      {isMinLg && <span className="mr-4 text-0_85">Have questions about pricing, billing, or teams?</span>}
      <button
        className={classnames('btn btn-reset font-bold', {
          'border-white text-white': !isMinLg,
          'border-primary text-primary': isMinLg,
        })}
        onClick={onClick}
        type="button"
      >
        Chat with us
      </button>
    </div>
  );
};

export default ChatWithUs;
