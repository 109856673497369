import { createSelector } from 'reselect';
import { hasCompliance, isComplete } from 'libs/mortgages';

const complianceSubStage = {
  Incomplete: 0,
  'Agent Review': 1,
  'Manager Review': 2,
  Verified: 3,
};

/**
 * Get All Programs from state.
 * @param {Object} state
 * @return {Object}
 */
const getAllMortgages = (state) => state.salesforce.mortgages.byId;

/**
 * Get the current search value from the state.
 * @param  {Object} state
 * @return {String}
 */
export const getSearch = (state) => state.salesforce.mortgages.search;

/**
 * Get all the Mortgae Sort options from the state.
 * @param  {Object} state
 * @return {Object}
 */
export const getSort = (state) => state.salesforce.mortgages.sort;

/**
 * Get he Mortgae Stage filter from the state.
 * @param  {Object} state
 * @return {Object}
 */
export const getStage = (state) => state.salesforce.mortgages.stage;

/**
 * Get the numberic mortgage ID and brokerage code from the string.
 * @param {String} mortgage
 * @returns {Object}
 */
const parseMortgageId = (mortgage) => {
  const arr = mortgage.split('-');
  return {
    code: arr[0].trim(),
    id: parseInt(arr.pop().trim(), 10),
  };
};

/**
 * Get All Programs.
 * @param {Object} state
 * @return {Array}
 */
export const getAll = createSelector(
  [getAllMortgages, getSort, getSearch, getStage],
  (mortgages, sort, search, stage) =>
    Object.keys(mortgages)
      .map((mortgageId) => mortgages[mortgageId])
      .filter((mortgage) => {
        const clone = {
          ...mortgage,
          client: mortgage.client || '',
        };
        return (
          clone.name.toLowerCase().includes(search.toLowerCase()) ||
          clone.client.toLowerCase().includes(search.toLowerCase()) ||
          (clone.stage && clone.stage.toLowerCase().includes(search.toLowerCase())) ||
          // eslint-disable-next-line max-len
          (clone.compliance_status && clone.compliance_status.toLowerCase().includes(search.toLowerCase()))
        );
      })
      .filter((mortgage) => (stage ? mortgage.stage === stage : true))
      .sort((a, b) => {
        if (sort.key === 'name') {
          const { code: aCode, id: aId } = parseMortgageId(a.name);
          const { code: bCode, id: bId } = parseMortgageId(b.name);

          if (aCode < bCode) return sort.direction === 'asc' ? -1 : 1;
          if (aCode > bCode) return sort.direction === 'asc' ? 1 : -1;
          if (aId < bId) return sort.direction === 'asc' ? -1 : 1;
          if (aId > bId) return sort.direction === 'asc' ? 1 : -1;
        } else {
          if (a[sort.key] < b[sort.key]) return sort.direction === 'asc' ? -1 : 1;
          if (a[sort.key] > b[sort.key]) return sort.direction === 'asc' ? 1 : -1;
        }

        return 0;
      })
);

/**
 * Get All Mortgages requiring compliance.
 * @param {Object} state
 * @return {Array}
 */
export const getAllAwaitingCompliance = createSelector([getAllMortgages], (mortgages) =>
  Object.keys(mortgages)
    .map((mortgageId) => mortgages[mortgageId])
    .filter((mortgage) => !hasCompliance(mortgage))
    .sort((a, b) => {
      if (a.closing_at < b.closing_at) return -1;
      if (a.closing_at > b.closing_at) return 1;
      return 0;
    })
);

/**
 * Get All Mortgages in the compliance stage.
 * @param {Object} state
 * @return {Array}
 */
export const getAllCompliance = createSelector([getAllMortgages], (mortgages) =>
  Object.keys(mortgages)
    .map((mortgageId) => mortgages[mortgageId])
    .filter((mortgage) => mortgage.stage === 'Compliance')
    .sort((a, b) => {
      const aStage = complianceSubStage[a.sub_stage] || 5;
      const bStage = complianceSubStage[b.sub_stage] || 5;
      if (aStage > bStage) return -1;
      if (aStage < bStage) return 1;
      return 0;
    })
);

/**
 * Get All Funded Mortgages.
 * @param {Object} state
 * @return {Array}
 */
export const getAllFunded = createSelector([getAllMortgages], (mortgages) =>
  Object.keys(mortgages)
    .map((mortgageId) => mortgages[mortgageId])
    .filter((mortgage) => isComplete(mortgage))
    .sort((a, b) => {
      if (a.closing_at < b.closing_at) return -1;
      if (a.closing_at > b.closing_at) return 1;
      return 0;
    })
);

/**
 * Get a mortgage from state by Id.
 * @param  {Object} state
 * @param  {String} mortgageId
 * @return {Object}
 */
export const getById = (state, mortgageId) => state.salesforce.mortgages.byId[mortgageId] || {};

/**
 * Get all the Mortgage Stages.
 * @param {Object}
 * @return {Array}
 */
export const getStages = createSelector([getAllMortgages], (mortgages) => {
  const stagesSet = Object.keys(mortgages)
    .map((mortgageId) => mortgages[mortgageId])
    .reduce((accum, mortgage) => {
      accum.add(mortgage.stage);
      return accum;
    }, new Set());

  return Array.from(stagesSet).sort();
});

/**
 * Check if the mortgages are loading
 * @param  {Objext} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.salesforce.mortgages.isLoading;
