import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = () => (
  <ToastContainer
    autoClose={4000}
    bodyClassName="font-bold"
    className="w-auto"
    closeButton={false}
    hideProgressBar
    position="bottom-center"
    theme="colored"
    transition={Slide}
  />
);

export default Toast;
