import moment from 'moment';
import { denormalize } from 'normalizr';
import schema from 'state/schema';

/**
 * Get mortgage paystubs from state by date.
 * @param  {Object} state
 * @param  {String} mortgageId
 * @return {Object}
 */
export const all = (state) =>
  Object.keys(state.salesforce.paystubs.byDate)
    .map((paystubId) =>
      denormalize(state.salesforce.paystubs.byDate[paystubId], schema.paystub, {
        ...state.salesforce,
      })
    )
    .sort((a, b) => {
      const one = moment(a.id, 'YYYY-MM-DD').unix();
      const two = moment(b.id, 'YYYY-MM-DD').unix();

      if (one > two) return -1;
      if (one === two) return 0;
      if (one < two) return 1;
      return 0;
    });

/**
 * Get mortgage paystubs from state by id.
 * @param  {Object} state
 * @param  {String} mortgageId
 * @return {Object}
 */
export const getById = (state, paystubId) =>
  denormalize(state.salesforce.paystubs.byId[paystubId], schema.paystub, {
    compensations: { ...state.salesforce.compensations.byId },
    expenses: { ...state.salesforce.expenses.byId },
  });

/**
 * Get the payroll reconciliation.
 * @param  {Object} state
 * @return {Object}
 */
export const getReconciliation = (state) =>
  denormalize(state.salesforce.paystubs.reconciliation, schema.paystub, {
    compensations: { ...state.salesforce.compensations.byId },
    expenses: { ...state.salesforce.expenses.byId },
  });

/**
 * Check if the mortgage paystubs are loading
 * @param  {Objext} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.salesforce.paystubs.isLoading;
