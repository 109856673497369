import React from 'react';
import PropTypes from 'prop-types';
import NavItem from './NavItem';

const Nav = ({ className, children }) => <div className={`nav navbar-nav ${className}`}>{children}</div>;

Nav.Item = NavItem;

/**
 * Default Props
 * @type {Object}
 */
Nav.defaultProps = {
  className: '',
};

/**
 * PropTypes Validation
 * @type {Object}
 */
Nav.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Nav;
