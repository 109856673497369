import React from 'react';
import PropTypes from 'prop-types';

const FormErrorAlert = (props) => {
  if (!props.show) return null;
  return <div className="alert alert-danger">{props.message}</div>;
};

FormErrorAlert.defaultProps = {
  message: null,
  show: false,
};

FormErrorAlert.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
};

export default FormErrorAlert;
