/**
 * Get mortgage expenses from state by mortgage id.
 * @param  {Object} state
 * @param  {String} mortgageId
 * @return {Object}
 */
export const getByMortgageId = (state, mortgageId) => {
  const mortgage = state.salesforce.mortgages.byId[mortgageId];

  if (!mortgage || !state.salesforce.expenses.byMortgageId[mortgage.id]) {
    return [];
  }

  return Object.keys(state.salesforce.expenses.byMortgageId[mortgage.id]).map(
    (compensationId) => state.salesforce.expenses.byMortgageId[mortgage.id][compensationId]
  );
};

/**
 * Check if the mortgage expenses are loading
 * @param  {Objext} state
 * @return {Boolean}
 */
export const isLoading = (state) => state.salesforce.expenses.isLoading;
