import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';

const FETCH = 'lenderspotlight/salesforce/paystubs/FETCH';
const FETCH_BY_DATE_SUCCESS = 'lenderspotlight/salesforce/paystubs/FETCH_BY_DATE_SUCCESS';
const FETCH_FAILED = 'lenderspotlight/salesforce/paystubs/FETCH_FAILED';
const FETCH_RECONCILIATION_SUCCESS = 'lenderspotlight/salesforce/paystubs/FETCH_RECONCILIATION_SUCCESS';
const FETCH_SUCCESS = 'lenderspotlight/salesforce/paystubs/FETCH_SUCCESS';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  FETCH,
  FETCH_BY_DATE_SUCCESS,
  FETCH_FAILED,
  FETCH_RECONCILIATION_SUCCESS,
  FETCH_SUCCESS,
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  fetch: PropTypes.func,
  fetchByDate: PropTypes.func,
  fetchReconciliation: PropTypes.func,
});

/**
 * Hydrate Initial State global LS object.
 * @type {Object}
 */
const initialState = {};

const initialReconciliationState = {
  additional: [],
  commissions: [],
  compensations: [],
  expenses: [],
  pending: [],
};

/**
 * Ids Paystub Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byDateReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

/**
 * ById Paystub Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BY_DATE_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.paystubs,
      };

    default:
      return state;
  }
}

/**
 * IsLoading Reducer
 * @param  {Boolean} state
 * @param  {Object} action
 * @return {Boolean}
 */
function isLoadingRecucer(state = false, action) {
  switch (action.type) {
    case FETCH:
      return true;

    case FETCH_BY_DATE_SUCCESS:
    case FETCH_FAILED:
    case FETCH_RECONCILIATION_SUCCESS:
    case FETCH_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * Reconciliation Reducer
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
function reconciliationReducer(state = initialReconciliationState, action) {
  if (action.type === FETCH_RECONCILIATION_SUCCESS) {
    return action.payload.entities.paystubs[action.payload.result];
  }

  return state;
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  byDate: byDateReducer,
  byId: byIdReducer,
  isLoading: isLoadingRecucer,
  reconciliation: reconciliationReducer,
});

/**
 * Fetch PayStubs by Date Success Action
 * @param {String} mortgageId
 * @param {Object} response
 * @return {Object}
 */
function fetchByDateSuccess(response) {
  const payload = normalize(response, schema.paystub);
  return { type: FETCH_BY_DATE_SUCCESS, payload };
}

/**
 * Fetch Reconciliation Success Action Creator
 * @param  {Object} response
 * @return {Object}
 */
function fetchReconciliationSuccess(response) {
  const payload = normalize(response, schema.paystub);
  return { type: FETCH_RECONCILIATION_SUCCESS, payload };
}

/**
 * Fetch PayStubs Thunk
 * @return {Function}
 */
export function fetch() {
  return (dispatch, getState) => {
    const hasPaystubs = Object.keys(getState().salesforce.paystubs.byDate).length > 0;
    if (hasPaystubs) return Promise.resolve();

    dispatch({ type: FETCH });
    return axios
      .get('/api/salesforce/paystubs')
      .then((response) => dispatch({ type: FETCH_SUCCESS, payload: response.data }))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}

/**
 * Fetch Pay Stubs for a given IOS week Thunk
 * @return {Function}
 */
export function fetchByDate(date) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/salesforce/paystubs/${date}`)
      .then((response) => dispatch(fetchByDateSuccess(response.data)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}

/**
 * Fetch the current weeks payroll reconciliation.
 * @return {Function}
 */
export function fetchReconciliation() {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get('/api/salesforce/payroll-reconciliation')
      .then((response) => dispatch(fetchReconciliationSuccess(response.data)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}
