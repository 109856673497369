import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { PersistGate } from 'redux-persist/integration/react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import 'instantsearch.css/themes/algolia.css';
import { Content, Context, ErrorBoundary, Loading, Notifications, Toast } from 'components/common';
import { Sidebar, Topbar } from 'layouts';
import { configureStore, history } from 'state';
import { UserDetailsModal } from 'components/modals';
import './bootstrap';

const Rates = lazy(() => import('./layouts/Rates' /* webpackChunkName: "rates", webpackPrefetch: true */));

const Scenarios = lazy(() => import('./layouts/Scenarios' /* webpackChunkName: "scenarios", webpackPrefetch: true */));

const Lenders = lazy(() => import('./layouts/Lenders' /* webpackChunkName: "lenders", webpackPrefetch: true */));

const Guidelines = lazy(
  () => import('./layouts/Guidelines' /* webpackChunkName: "guidelines", webpackPrefetch: true */)
);

const AltLending = lazy(
  () => import('./layouts/AltLending' /* webpackChunkName: "alt-lending", webpackPrefetch: true */)
);

const AI = lazy(() => import('./layouts/AI' /* webpackChunkName: "ai", webpackPrefetch: true */));

const Settings = lazy(() => import('./layouts/Settings' /* webpackChunkName: "settings", webpackPrefetch: true */));

const PayAssist = lazy(() => import('./layouts/PayAssist' /* webpackChunkName: "payassist", webpackPrefetch: true */));

const { store, persistor } = configureStore();

const App = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <GoogleReCaptchaProvider reCaptchaKey={window.RECAPTCHA_SITE_KEY}>
            <Sidebar />
            <UserDetailsModal />
            <Context className="container-fluid with-sidebar">
              <Topbar />
              <Notifications />
              <Content>
                {/* We use this inner boundary to maintain logo/nav UI in case of errors */}
                <ErrorBoundary>
                  <Suspense fallback={<Loading pastDelay />}>
                    <Switch>
                      <Route path="/rates" component={Rates} />
                      <Route path="/scenarios" component={Scenarios} />
                      <Route path="/lenders" component={Lenders} />
                      <Route path="/guidelines" component={Guidelines} />
                      <Route path="/alternative-lending" component={AltLending} />
                      <Route path="/ai" component={AI} />
                      <Route path="/settings" component={Settings} />
                      <Route path="/pay-assist" component={PayAssist} />
                      <Redirect to="/rates" />
                    </Switch>
                  </Suspense>
                  <Toast />
                </ErrorBoundary>
              </Content>
            </Context>
          </GoogleReCaptchaProvider>
        </Router>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

const root = createRoot(document.getElementById('root') || document.createElement('div'));

root.render(<App />);

export default App;
