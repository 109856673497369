import React from 'react';
import PropTypes from 'prop-types';
import { location as LocationPropTypes } from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

const isActive = (location, paths) =>
  paths.reduce((accum, path) => {
    if (location.pathname.includes(path)) {
      return true;
    }
    return accum;
  }, false);

const NavItem = (props) => {
  const classes = ['nav-item', 'nav-link'];

  if (props.hidden) {
    return null;
  }

  if (isActive(props.location, props.paths)) {
    classes.push('active');
  }

  return (
    <a
      key={props.title}
      className={classes.join(' ')}
      href={props.paths[0]}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.title}
    </a>
  );
};

/**
 * Default Props
 * @type {Object}
 */
NavItem.defaultProps = {
  disabled: false,
  hidden: false,
  onClick: () => {},
  title: '',
};

/**
 * PropTypes Validation
 * @type {Object}
 */
NavItem.propTypes = {
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  location: LocationPropTypes.isRequired,
  onClick: PropTypes.func,
  paths: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
};

export default withRouter(NavItem);
