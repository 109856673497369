import React from 'react';
import PropTypes from 'prop-types';

const FormSuccessAlert = (props) => {
  if (!props.show) return null;
  return <div className="alert alert-success">{props.message}</div>;
};

FormSuccessAlert.defaultProps = {
  message: null,
  show: false,
};

FormSuccessAlert.propTypes = {
  message: PropTypes.string,
  show: PropTypes.bool,
};

export default FormSuccessAlert;
