import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import schema from 'state/schema';

const CREATE = 'lenderspotlight/salesforce/mortgages/CREATE';
const CREATE_FAILED = 'lenderspotlight/salesforce/mortgages/CREATE_FAILED';
const CREATE_SUCCESS = 'lenderspotlight/salesforce/mortgages/CREATE_SUCCESS';
const FETCH = 'lenderspotlight/salesforce/mortgages/FETCH';
const FETCH_BY_ID_SUCCESS = 'lenderspotlight/salesforce/mortgages/FETCH_BY_ID_SUCCESS';
const FETCH_FAILED = 'lenderspotlight/salesforce/mortgages/FETCH_FAILED';
const FETCH_SUCCESS = 'lenderspotlight/salesforce/mortgages/FETCH_SUCCESS';
const SEARCH = 'lenderspotlight/salesforce/mortgages/SEARCH';
const SEARCH_RESET = 'lenderspotlight/salesforce/mortgages/SEARCH_RESET';
const SET_SORT = 'lenderspotlight/salesforce/mortgages/SET_SORT';
const SET_STAGE = 'lenderspotlight/salesforce/mortgages/SET_STAGE';
const UPDATE = 'lenderspotlight/salesforce/mortgages/UPDATE';
const UPDATE_FAILED = 'lenderspotlight/salesforce/mortgages/UPDATE_FAILED';
const UPDATE_SUCCESS = 'lenderspotlight/salesforce/mortgages/UPDATE_SUCCESS';

/**
 * Action Types
 * @type {Object}
 */
export const actionTypes = {
  CREATE,
  CREATE_FAILED,
  CREATE_SUCCESS,
  FETCH,
  FETCH_BY_ID_SUCCESS,
  FETCH_FAILED,
  FETCH_SUCCESS,
  SEARCH,
  SEARCH_RESET,
  SET_SORT,
  UPDATE,
  UPDATE_FAILED,
  UPDATE_SUCCESS,
};

/**
 * PropTypes Validation
 * @type {Function}
 */
export const propTypes = PropTypes.shape({
  clearSearch: PropTypes.func,
  create: PropTypes.func,
  fetchById: PropTypes.func,
  fetchAll: PropTypes.func,
  search: PropTypes.func,
  setSort: PropTypes.func,
  updateById: PropTypes.func,
});

/**
 * Hydrate Initial State from global LS object.
 * @type {Object}
 */
const initialState = {};

const initialSearchState = '';

const initialSortState = {
  direction: 'desc',
  key: 'name',
};

/**
 * ById Lender Reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Object}
 */
function byIdReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.mortgages,
      };

    case FETCH_BY_ID_SUCCESS:
    case FETCH_SUCCESS:
      return {
        ...action.payload.entities.mortgages,
      };

    default:
      return state;
  }
}

/**
 * IsLoading Reducer
 * @param  {Boolean} state
 * @param  {Object} action
 * @return {Boolean}
 */
function isLoadingRecucer(state = false, action) {
  switch (action.type) {
    case CREATE:
    case FETCH:
    case UPDATE:
      return true;

    case CREATE_FAILED:
    case CREATE_SUCCESS:
    case FETCH_BY_ID_SUCCESS:
    case FETCH_FAILED:
    case FETCH_SUCCESS:
    case UPDATE_FAILED:
    case UPDATE_SUCCESS:
      return false;

    default:
      return state;
  }
}

/**
 * Search Reducer
 * @param  {String} state
 * @param  {Object} action
 * @return {String}
 */
function searchReducer(state = initialSearchState, action) {
  switch (action.type) {
    case SEARCH:
      return action.payload;

    case SEARCH_RESET:
      return initialSearchState;

    default:
      return state;
  }
}

/**
 * Set the Stage to in which to filter results.
 * @param {String|Null} state
 * @param {Object} action
 */
function stageReducer(state = null, action) {
  switch (action.type) {
    case SET_STAGE:
      return action.payload;
    default:
      return state;
  }
}

/**
 * Sort Reducer
 * @param  {Object} state
 * @param  {Object} action
 * @return {Object}
 */
function sortReducer(state = initialSortState, action) {
  if (action.type === SET_SORT) {
    return action.payload;
  }

  return state;
}

/**
 * Export Lender Reducer
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
  isLoading: isLoadingRecucer,
  search: searchReducer,
  sort: sortReducer,
  stage: stageReducer,
});

/**
 * Fetch By Id Mortgages Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchByIdSuccess(response) {
  const payload = normalize(response, schema.mortgage);
  return { type: FETCH_SUCCESS, payload };
}

/**
 * Fetch Mortgages Success Action
 * @param {Object} response
 * @return {Object}
 */
function fetchSuccess(response) {
  const payload = normalize(response, [schema.mortgage]);
  return { type: FETCH_SUCCESS, payload };
}

/**
 * Update by Id Success Action Creator
 * @param  {Object} response
 * @return {Object}
 */
function updateByIdSuccess(response) {
  const payload = normalize(response, [schema.mortgage]);
  return { type: UPDATE_SUCCESS, payload };
}

/**
 * Clear the current search value.
 * @return {Function}
 */
export function clearSearch() {
  return (dispatch) => dispatch({ type: SEARCH_RESET });
}

/**
 * Fetch Mortgages Thunk
 * @return {Function}
 */
export function fetchAll() {
  return (dispatch, getState) => {
    if (Object.keys(getState().salesforce.mortgages.byId).length > 0) {
      return Promise.resolve({ type: 'NOOP' });
    }

    dispatch({ type: FETCH });
    return axios
      .get('/api/salesforce/mortgages')
      .then((response) => dispatch(fetchSuccess(response.data)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}

/**
 * Fetch Mortgage By Id Thunk
 * @param  {Number} lenderId
 * @return {Function}
 */
export function fetchById(mortgageId) {
  return (dispatch) => {
    dispatch({ type: FETCH });
    return axios
      .get(`/api/salesforce/morgages/${mortgageId}`)
      .then((response) => dispatch(fetchByIdSuccess(response.data)))
      .catch((error) => dispatch({ type: FETCH_FAILED, error }));
  };
}

/**
 * Create a new mortgage object.
 * @param  {Object} data
 * @return {Function}
 */
export function create(data) {
  return (dispatch, getState) => {
    dispatch({ type: CREATE });

    const body = { ...data };

    if (body.compliance) {
      // eslint-disable-next-line no-param-reassign
      body.compliance = Object.keys(body.compliance).join(';');
    }

    Object.assign(body, {
      compliance_status: 'Ready for Review',
      stage: 'Compliance',
    });

    return axios
      .post('/api/salesforce/mortgages', body)
      .then(() => fetchAll()(dispatch, getState))
      .catch((error) => dispatch({ type: CREATE_FAILED, error }));
  };
}

/**
 * Set the search value.
 * @param  {String} payload
 * @return {Function}
 */
export function search(payload) {
  return (dispatch) => dispatch({ type: SEARCH, payload });
}

/**
 * Set the Mortgage sort key and direction.
 * @param {String} key
 * @param {String} direction
 */
export function setSort({ key = 'name', direction = 'asc' }) {
  return (dispatch) =>
    dispatch({
      type: SET_SORT,
      payload: { direction, key },
    });
}

/**
 * Set the Mortgage Stage filter.
 * @param {String} stage
 */
export function setStage(stage) {
  return (dispatch) =>
    dispatch({
      type: SET_STAGE,
      payload: stage,
    });
}

/**
 * Update by Id Thunk
 * @param  {String} mortgageId
 * @param  {Object} data
 * @return {Function}
 */
export function updateById(mortgageId, data) {
  return (dispatch) => {
    dispatch({ type: UPDATE });

    const body = { ...data };

    if (body.compliance) {
      // eslint-disable-next-line no-param-reassign
      body.compliance = Object.keys(body.compliance).join(';');
    }

    Object.assign(body, {
      allow_finastra_updates: false,
      compliance_status: 'Ready for Review',
      stage: 'Compliance',
      sub_stage: 'Manager Review',
    });

    return axios
      .put(`/api/salesforce/mortgages/${mortgageId}/compliance`, body)
      .then((response) => dispatch(updateByIdSuccess(response.data)))
      .catch((error) => dispatch({ type: UPDATE_FAILED, error }));
  };
}
